#container { background-color: #f4f4f4; }
.account_wrap { max-width: 620px; margin: 0 auto; padding: 100px 20px; }
.account_box { width: 100%; padding: 50px 70px 70px; border: 1px solid #e8e8e8; border-radius: 10px; background-color: #fff; }
.account_title { margin-bottom: 40px; font-size: 28px; font-weight: 700; text-align: center; }
.account_desc { margin-bottom: 20px; color: #888; }

.consent_wrap { margin: 30px 0; }
.consent_item + .consent_item { margin-top: 20px; }
.consent_item .consent_box { height: 130px; padding: 15px 20px; border: 1px solid #e2e2e2; border-radius: 5px; overflow: hidden; font-size: 14px; line-height: 1.5; overflow: auto; }
.consent_item .enter_item .enter.check span { font-size: 18px; letter-spacing: -0.36px; }
.consent_item .enter_item .enter.check span em { color: #2763ba; margin-right: 5px; }

.enter.flex { justify-content: flex-start; }
.enter.flex .radio_item { width: 50%; }

.enter_item .cc_btn { font-size: 16px; }
.enter_item.option { display: flex; align-items: center; justify-content: space-between; color: #555; }
.enter_item.option .find_account_btn { padding-bottom: 2px; border-bottom: 1px solid #555; }

.account_btn_wrap { margin-top: 50px; }
.account_btn_wrap .cc_btn + .cc_btn { margin-top: 15px; }

.result_wrap { margin-top: 50px; padding-top: 40px; border-top: 1px solid #e2e2e2; }
.result_wrap .result_box { display: flex; align-items: center; justify-content: center; height: 90px; padding: 20px; background-color: #f3f3f3; border-radius: 5px; text-align: center; }
.result_wrap .result_box span { display: inline-block; color: #1742d1; font-weight: 700; }

@media all and (max-width: 1000px) {
    .account_wrap { padding-top: 50px; }
    .account_box { padding: 30px 40px 40px; }
    .account_title { margin-bottom: 20px; font-size: 24px; }
    .account_desc { margin-bottom: 10px; font-size: 14px; }

    .consent_wrap { margin: 20px 0; }

    .enter_item .cc_btn { font-size: 14px; }
    .enter_item.option .find_account_btn { font-size: 14px; }

    .account_btn_wrap { margin-top: 30px; }
    .account_btn_wrap .cc_btn { font-size: 16px; }
    .account_btn_wrap .cc_btn + .cc_btn { margin-top: 10px; }

    .result_wrap  { margin-top: 30px; padding-top: 30px; }
}

@media all and (max-width: 640px) {
    #container { background-color: #fff; }
    .account_wrap { padding-top: 30px; }
    .account_box { padding: 0; border: 0; }
}