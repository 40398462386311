.loading {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loading span {
    display: inline-block;
    margin: 7px;
    padding: 10px;
    width: 10px;
    height: 10px;
    background-color: gray;
    border-radius: 50%;
    animation: loading 1s linear infinite
}

@keyframes loading {
    0%,
    100% {
        opacity: 0;
        transform: scale(0.5);
    }
    50% {
        opacity: 1;
        transform: scale(1.2);
    }
}

.loading span:nth-child(1) {
    animation-delay: 0s;
    background-color: #2763BAFF;
}

.loading span:nth-child(2) {
    animation-delay: 0.2s;
    background-color: #2763BAFF;
}
.loading span:nth-child(3) {
    animation-delay: 0.4s;
    background-color: #2763BAFF;
}
