.sr_only { position: absolute; margin: -1px; width: 1px; height: 1px; overflow: hidden; clip: rect(0,0,0,0); }

header { width: 100%; position: fixed; top: 0; left: 0; border-bottom: 1px solid #e2e2e2; background-color: #fff; z-index: 999; }
header > div { display: flex; align-items: center; justify-content: space-between; max-width: 1040px; min-width: 320px; height: 75px; margin: 0 auto; padding: 0 20px; }
/* header .head_logo { width: 351px; height: 28px; } */
header .head_logo { width: 189px; height: 28px; }
header .head_logo a { display: block; width: 100%; height: 100%; background: url(../img/img-logo.png) no-repeat center / 100%; }
header .head_btn { display: flex; gap: 10px; }
header .head_btn .my_info_btn { width: 40px; height: 40px; background: url(../img/btn-mypage.png) no-repeat center / 100%; }
header .head_btn .login_btn { display: flex; align-items: center; justify-content: center; width: 90px; height: 40px; border-radius: 20px; border: 1px solid #004ec8; color: #004ec8; font-size: 14px; letter-spacing: -0.7px; }
header .head_btn .login_btn.logout { border-color: #ccc; background-color: #f4f4f4; color: #555; }

#wrapper { padding-top: 75px; min-width: 320px; }

#container { padding-bottom: 120px; }
.scroll_btn { display: block; position: fixed; right: 15px; width: 40px; height: 40px; background: #1742d1; border-radius: 20px; outline: none; z-index: 10; transition: height 0.2s; }
.scroll_top_btn { bottom: 70px; visibility: hidden; opacity: 0; }
.scroll_bottom_btn { bottom: 20px; transform: rotate(-180deg); }
.scroll_btn span, .scroll_btn::before, .scroll_btn::after { content: ""; display: block; position: absolute; top: 14px; left: 50%; width: 15px; height: 2px; background-color: #fff; transform-origin: left 50%; }
.scroll_btn span { bottom: auto; margin-left: -1px; width: 2px; height: 0; transition: all 0.2s linear; }
.scroll_btn::before, .scroll_btn::after { transition: top 0.4s; }
.scroll_btn::before { transform: rotate(135deg); }
.scroll_btn::after { transform: rotate(45deg); }
.is_online .scroll_btn:hover, .is_online .scroll_btn:focus { height: 45px; }
.is_online .scroll_btn:hover::before, .is_online .scroll_btn:hover::after, .is_online .scroll_btn:focus::before, .is_online .scroll_btn:focus::after { top: 8px; }
.is_online .scroll_btn:hover span, .is_online .scroll_btn:focus span { top: auto; bottom: 8px; height: 26px; }

.cc_btn { display: inline-flex; justify-content: center; align-items: center; padding: 10px; width: 100%; height: 50px; line-height: 1.2; border-radius: 5px; background-color: #1742d1; font-weight: 700; font-size: 18px; color: #fff; }
.cc_btn.bg_white { border: 1px solid #ccc; background-color: #fff; font-weight: 400; color: #000; }
.cc_btn.bg_white_blue { border: 1px solid #004ec8; background-color: #fff; color: #004ec8; }
.cc_btn.bg_white_black { border: 1px solid #555; background-color: #fff; color: #000; }
.cc_btn.bg_gray { color: #222; background-color: #ececec; font-weight: 400; }
.cc_btn.bg_darkgray { color: #fff; background-color: #888; font-weight: 400; }
.is-online .cc_btn { transition: box-shadow 0.3s ease-in-out; }
.is-online .cc_btn:hover { box-shadow: 0px 0px 0px 3px #fff, 0px 0px 0px 5px #e2e2e2; }
.is-online .cc_btn[disabled]:hover, .is-online .cc_btn.disabled:hover, .is-online .cc_btn.on:hover { box-shadow: none !important; }
div.cc_btn { box-shadow: none !important; }

.fc_blue { color: #004ec8 !important; }

.enter_item .item_title { display: block; width: 100%; margin-bottom: 10px; font-size: 14px; color: #555; }
.enter_item .item_title.essential span { position: relative; padding-right: 10px; }
.enter_item .item_title.essential span::after { content: "*"; display: flex; align-items: center; justify-content: center; width: 5px; height: 5px; position: absolute; top: 60%; right: 0; transform: translate(0, -50%); color: #ef3131; }
.enter_item + .enter_item { margin-top: 10px; }
.enter_item .enter + .enter { margin-top: 10px; }
.enter_item .enter.flex { display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; }
.enter_item .enter.flex input { width: calc(100% - 150px); }
.enter_item .enter.flex .cc_btn { width: 140px; font-size: 16px; }
.enter_item .enter .check_item label, .enter_item .enter .radio_item label { cursor: pointer; display: inline-flex; align-items: center; position: relative; padding: 10px 0; }
.enter_item .enter .check_item input, .enter_item .enter .radio_item input { height: auto; position: absolute; left: 0; opacity: 0; z-index: -1; }
.enter_item .enter .check_item span, .enter_item .enter .radio_item span { position: relative; padding-left: 35px; padding-bottom: 2px; font-size: 16px; text-align: left; }
.enter_item .enter .check_item span::before, .enter_item .enter .radio_item span::before { content: ""; width: 24px; height: 24px; position: absolute; top: 50%; left: 0; transform: translate(0, -50%); }
.enter_item .enter .check_item span::before { background: url(../img/img-chk-off.png) no-repeat center / 100%; border-radius: 5px; }
.enter_item .enter .radio_item span::before { background: url(../img/radio-chk-off.png) no-repeat center / 100%; border-radius: 12px; }
.enter_item .enter .check_item input:checked ~ span::before { background: url(../img/img-chk-on.png) no-repeat center / 100%; }
.enter_item .enter .radio_item input:checked ~ span::before { background: url(../img/radio-chk-on.png) no-repeat center / 100%; }
.enter_item .enter .check_item input:focus + span::before, .enter_item .enter .radio_item input:focus + span::before  { box-shadow: 0 0 0 3px #fff, 0 0 0 5px #222; outline: 0; }
.enter_item .enter .check_item .essential, .enter_item .enter .radio_item .essential { color: #2763ba; padding-right: 3px; }
.enter_item .enter select option[disabled] { display: none; }
.enter_item.file { position: relative; }
.enter_item.file input[readonly] { background-color: #fff; }
.enter_item.file input[type="file"] { cursor: pointer; position: absolute; width: 100% !important; opacity: 0; }
.enter_desc { margin-bottom: 30px; color: #555; letter-spacing: -0.32px; }

.no_con { width: 100%; }
.no_con div { display: flex; justify-content: center; align-items: center; height: 30vh; padding: 15px; font-size: 16px; }

.editor_content { line-height: 1.5; text-overflow: ellipsis; word-wrap: break-word; word-break: keep-all; }
.editor_content * { font-family: inherit !important; }
.editor_content h1, .editor_content h2, .editor_content h3, .editor_content h4, .editor_content h5, .editor_content h6 { font-weight: bold; font-weight: 700; line-height: 1.5em; }
.editor_content h1 { margin: 18px 0; font-size: 26px; }
.editor_content h2 { margin: 18px 0; font-size: 20px; }
.editor_content h3 { margin: 15px 0; font-size: 15px; }
.editor_content h4 { margin: 18px 0; font-size: 13px; }
.editor_content h5 { margin: 18px 0; font-size: 11px; }
.editor_content h6 { margin: 20px 0; font-size: 10px; }
.editor_content table, .editor_content img { max-width: 100% !important; }
.editor_content table { margin: auto; table-layout: auto; }
.editor_content td, .editor_content th { border: 1px solid #999; padding: 6px 13px; }
.editor_content li { margin-left: 15px; }
.editor_content ol { list-style: decimal }
.editor_content ul { list-style: disc }
.editor_content a[href] { text-decoration: underline; }
.editor_content i { font-style: italic; }

.cc_paging { display: flex; flex-direction: row; justify-content: center; align-items: center; margin: 50px 0; text-align: center; }
.cc_paging a { display: inline-block; width: 42px; height: 42px; line-height: 42px; margin: 0 5px; border-radius: 50%; overflow: hidden; }
.cc_paging a.num { margin: 0 2px; }
.cc_paging a.num.on { font-weight: 700; color: #004ec8; }
.cc_paging a.arrow { background-position: center; background-repeat: no-repeat; background-size: 100%; }
.cc_paging a.arrow.first { background-image: url(../img/img-back.png); }
.cc_paging a.arrow.prev { background-image: url(../img/img-back-2.png); }
.cc_paging a.arrow.next { background-image: url(../img/img-next.png); }
.cc_paging a.arrow.last { background-image: url(../img/img-next-2.png); }

footer { border-top: 1px solid #e2e2e2; background-color: #f0f0f0; }
footer > div { display: flex; align-items: center; justify-content: space-between; max-width: 1040px; margin: 0 auto; padding: 35px 20px; }
footer .copyright { font-size: 13px; letter-spacing: -0.26px; color: #666; }
footer .email_refusal_btn { font-size: 14px; color: #333; text-decoration: underline; }

.bg_strong { background-color: #f2f7fe; }

.cc_table { border-top: 1px solid #222; }
.cc_table table { width: 100%; }
.cc_table tr { border-bottom: 1px solid #eee; }
.cc_table th, .cc_table td { padding: 5px 10px; height: 70px; text-align: center; }
.cc_table .ta_left { text-align: left; }

.underline_item .line { background: linear-gradient(#333, #333) no-repeat bottom left / 0% 1px; }
.underline_item.effect .line { transition: background 0.4s ease-in-out; }
.underline_item:not(.effect) .line { background-size: 100% 1px; }
.is-online .underline_item.effect:hover .line { background-size: 100% 1px; }

.cc_modal_wrap { display: flex; justify-content: center; align-items: center; width: 100%; height: 100%; position: fixed; top: 0; left: 0; padding: 20px; background-color: rgba(0, 0, 0, 0.7); z-index: 999; }
.cc_modal_wrap img { display: block; max-width: 100% !important; max-height: 100% !important; width: auto !important; height: auto !important; margin: 0 auto !important; }
.cc_modal_wrap > div { max-width: 580px; min-width: 300px; width: 100%; min-height: 200px; position: relative; background-color: #fff; box-shadow: 5.9px 8.1px 40px 0 rgba(0, 0, 0, 0.2); border-radius: 10px; overflow: hidden; }
.cc_modal_wrap .md_header { display: flex; justify-content: center; align-items: center; min-height: 60px; position: relative; padding: 10px 55px; border-bottom: 1px solid #e2e2e2; }
.cc_modal_wrap .md_title { font-size: 20px; line-height: 1.3; letter-spacing: -0.4px; font-weight: 500; color: #222; text-align: center; }
.cc_modal_wrap .md_title span { display: inline-block; }
.cc_modal_wrap .md_body > div { padding: 20px; }
.cc_modal_wrap .md_body > div > div { padding: 20px; max-height: 60vh; overflow: auto; }
.cc_modal_wrap .md_body .md_tabs { display: flex; align-items: center; gap: 30px; margin-bottom: 40px; }
.cc_modal_wrap .md_body .md_tabs .tab_item { position: relative; font-size: 20px; font-weight: 700; color: #888; }
.cc_modal_wrap .md_body .md_tabs .tab_item.on { color: #2763ba; }
.cc_modal_wrap .md_body .md_tabs .tab_item.on::after { content: ""; width: 100%; height: 3px; position: absolute; bottom: -7px; left: 0; background-color: #2763ba; }
.cc_modal_wrap .md_footer { padding: 0 40px; padding-bottom: 60px; }
.cc_modal_wrap .md_footer .bottom_btn_wrap { display: flex; align-items: center; justify-content: center; gap: 10px; margin: 0; }
.cc_modal_wrap .md_footer .bottom_btn_wrap .cc_btn { width: 100%; }
.cc_modal_wrap .md_close_btn { width: 30px; height: 30px; position: absolute; top: 15px; right: 20px; background: url(../img/img-x.png) no-repeat center / 14px; }
.cc_modal_wrap .no_con div { height: 15vh; }

.cc_modal_wrap .md_footer .bottom_btn_wrap.half { justify-content: space-between; gap: 1px; width: 100%; height: 100%; position: relative; }
.cc_modal_wrap .md_footer .bottom_btn_wrap.half::after { content: ""; width: 1px; height: 100%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: #e2e2e2; }
.cc_modal_wrap .md_footer .bottom_btn_wrap button.underline { display: flex; align-items: center; justify-content: center; width: 100%; height: 100%; }
.cc_modal_wrap .md_footer .bottom_btn_wrap button.underline span { padding-bottom: 5px; border-bottom: 1px solid #555; }
.cc_modal_wrap .md_footer .bottom_btn_wrap.half button { width: 100%; }

.cc_modal_wrap.ban_email > div { max-width: 480px; }

.cc_modal_wrap.change_team_info .md_body > div { padding: 0; }
.cc_modal_wrap.change_team_info .enter_item + .enter_item { margin-top: 10px; }
.cc_modal_wrap.change_team_info textarea { max-height: 200px; }
.cc_modal_wrap.change_team_info .md_footer { padding: 0 20px 20px; }
.cc_modal_wrap.change_team_info .md_footer .bottom_btn_wrap .cc_btn { max-width: 200px; }

.cc_modal_wrap.change_leader > div { max-width: 400px; }
.cc_modal_wrap.change_leader .md_body > div { padding: 0; }
.cc_modal_wrap.change_leader .md_footer { padding: 0 20px 20px; }
.cc_modal_wrap.change_leader .md_footer .bottom_btn_wrap .cc_btn { max-width: 200px; }

.cc_modal_wrap.reset_password > div { max-width: 440px; }
.cc_modal_wrap.reset_password .md_body > div { padding: 0; }
.cc_modal_wrap.reset_password p { font-size: 14px; color: #555; padding-bottom: 10px; }
.cc_modal_wrap.reset_password .enter_item + .enter_item { margin-top: 10px; }
.cc_modal_wrap.reset_password .md_footer { padding: 0 20px 20px; }
.cc_modal_wrap.reset_password .md_footer .bottom_btn_wrap .cc_btn { max-width: 200px; }

.cc_modal_wrap.team_info > div { max-width: 800px; }
.cc_modal_wrap.team_info .team_enter_box { padding: 0; background-color: #fff; }
.cc_modal_wrap.team_info .team_enter_box .consent_item + .consent_item { margin-top: 20px; }
.cc_modal_wrap.team_info .team_enter_box .consent_wrap .consent_box { padding: 10px 20px; border: 1px solid #e2e2e2; border-radius: 5px; font-size: 14px; color: #555; overflow: auto; }
.cc_modal_wrap.team_info .team_enter_box > div + div { margin-top: 35px; padding-top: 35px; border-top: 1px solid #e2e2e2; }
.cc_modal_wrap.team_info .team_enter_box > .enter_wrap + .consent_wrap { margin-top: 25px; }
.cc_modal_wrap.team_info .md_footer .cc_btn { max-width: 130px; }

.default_md > div { max-width: 800px; }
.default_md .con ~ .con { margin-top: 40px; }
.default_md h1 { margin-bottom: 15px; font-size: 20px; }
.default_md .con .cc_btn { width: 130px; font-size: 16px; }
.default_md .md_body > div > div { max-height: 70vh; }

.dialog_wrap { display: flex; justify-content: center; align-items: center; width: 100%; height: 100%; position: fixed; top: 0; left: 0; padding: 20px; z-index: 999; word-break: keep-all; }
.dialog_wrap > div { max-width: 380px; width: 100%; position: relative; font-size: 16px; font-weight: normal; color: #222; text-align: center; line-height: 1.5; border-radius: 10px; overflow: hidden; box-shadow: 6px 8px 40px 0 rgb(0 0 0 / 20%); }
.dialog_wrap .middle { padding: 40px 30px 30px; min-height: 100px; letter-spacing: -1px; background-color: #fff; text-align: center; }
.dialog_wrap .bottom .btns { display: flex; justify-content: center; align-items: center; gap: 10px; padding: 0 30px 30px; background-color: #fff; }
.dialog_wrap .bottom .btns a, .dialog_wrap .bottom .btns button { display: flex; justify-content: center; align-items: center; width: 100%; min-height: 50px; }
.dialog_wrap .bottom .btns .cc_btn.bg_white { font-weight: 400; }
.dialog_wrap .md_close_btn { width: 30px; height: 30px; position: absolute; top: 9px; right: 9px; background: url(../img/img-x.png) no-repeat center / 18px; }

@media all and (max-width: 1000px) {
    header > div { height: 60px; }
    header .head_logo { width: 121px; height: 18px; }
    header .head_btn .my_info_btn { width: 35px; height: 35px; }
    header .head_btn .login_btn { height: 35px; }

    #wrapper { padding-top: 60px; }

    .enter_item .enter input { padding: 0 10px; font-size: 14px; }
    .enter_item .enter.flex input { width: calc(100% - 130px); }
    .enter_item .enter.flex .cc_btn { width: 120px; font-size: 14px; letter-spacing: -0.5px; }
    .enter_item .enter .check_item span::before, .enter_item .enter .radio_item span::before { width: 20px; height: 20px; }
    .enter_item .enter .check_item span, .enter_item .enter .radio_item span { padding-left: 30px; font-size: 14px; }
    .enter_item .enter .radio_item span::before { border-radius: 10px; }
    .enter_item .enter select { font-size: 14px; }

    .no_con { display: block !important; }

    .cc_modal_wrap.team_info .team_enter_box > div + div { margin-top: 20px; padding-top: 15px; border-top: 1px solid #e2e2e2; }
    .cc_modal_wrap.team_info .team_enter_box > .enter_wrap + .consent_wrap { margin-top: 15px; padding-top: 20px; }
}

@media all and (max-width: 640px) {
    .cc_modal_wrap .md_header { padding: 15px 45px; }
    .cc_modal_wrap .md_title { font-size: 18px; }
    .cc_modal_wrap .md_body > div { padding: 10px; }
    .cc_modal_wrap .md_body > div > div { padding: 10px; }
    .cc_modal_wrap .md_body .md_tabs { gap: 10px; }
    .cc_modal_wrap .md_footer { padding: 0 20px 20px; }
    .cc_modal_wrap .md_close_btn { top: 15px; right: 15px; }

    .cc_modal_wrap.team_info .md_title { font-size: 16px; }
}

@media all and (max-width: 580px) {
    .default_md .cc_table colgroup, .default_md .cc_table thead { display: none; }
    .default_md .cc_table table, .default_md .cc_table tbody, .default_md .cc_table tr, .default_md .cc_table th, .default_md .cc_table td { display: block; }
    .default_md .cc_table tr { padding: 10px 5px; }
    .default_md .cc_table th, .default_md .cc_table td { height: auto; font-size: 14px; text-align: left; }
    .default_md .cc_table td { padding: 5px; }
    .default_md .cc_table td::before { content: attr(data-th)" : "; font-weight: 300; color: #666; }
}

@media all and (max-width: 480px) {
    .enter_item .enter.flex input { width: calc(100% - 110px); }
    .enter_item .enter.flex .cc_btn { width: 100px; }
}

@media all and (max-width: 440px) {
    header .head_logo { width: 94px; height: 14px; }
    header .head_btn { gap: 5px; }
    header .head_btn .login_btn { width: 70px; }

    .cc_modal_wrap.change_team_info .enter_item .enter.flex, .cc_modal_wrap.change_leader .enter_item .enter.flex { flex-wrap: wrap; align-items: flex-start; }
    .cc_modal_wrap.change_team_info .enter_item .enter.flex .radio_item, .cc_modal_wrap.change_leader .enter_item .enter.flex .radio_item { width: 50%; }
}

@media print { @page { size: A4; margin: 0 5%; }
    * { -webkit-print-color-adjust: exact; print-color-adjust: exact; }
    header, footer, #wrapper, .scroll-btn, .modal-footer { display: none; }
    .modal-wrap { display: block; padding: 3vh 0; background: transparent; }
    .modal-wrap .modal-dialog { padding: 0; max-width: 100%; box-shadow: none; }
}