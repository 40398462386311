.visual_sec { display: flex; align-items: center; justify-content: flex-start; height: 330px; background: url(../img/main-banner.png) no-repeat center / cover; }
.visual_sec > div { display: flex; flex-direction: column; max-width: 1040px; width: 100%; margin: 0 auto; padding: 0 20px; color: #fff; }
.visual_sec h2 { font-size: 74px; font-weight: 900; }
.visual_sec h3 { margin: -10px 0 10px; font-size: 36px; font-weight: 700; color: #a4c5ff; }
.visual_sec h3 em { color: #fff; }
.visual_sec p { font-size: 14px; font-weight: 500; }

.challenge_wrap { max-width: 1040px; margin: 70px auto 50px; padding: 0 20px; }
.challenge_wrap .challenge_info { display: flex; align-items: center; gap: 1px; max-width: 650px; height: 70px; position: relative; margin: 0 auto 55px; border: 1px solid #e2e2e2; border-radius: 10px; }
.challenge_wrap .challenge_info::after { content: ""; width: 1px; height: 26px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: #bbc5cf; }
.challenge_wrap .challenge_info .info_item { display: flex; align-items: center; justify-content: center; gap: 15px; width: 100%; font-size: 20px; font-weight: 700; }
.challenge_wrap .challenge_info .info_item .info_image { width: 36px; height: 36px; }
.challenge_wrap .challenge_info .info_item .info_image img { width: 100%; }
.challenge_wrap .challenge_info .info_item em { font-size: 24px; color: #004ec8; }

.challenge_list { display: flex; flex-wrap: wrap; gap: 30px; }
.challenge_list .challenge_item { width: calc(33.33% - 20px); border-radius: 10px; overflow: hidden; }
.challenge_list .challenge_item .item_thumb { display: flex; align-items: center; justify-content: center; position: relative; height: 205px; object-fit: cover; overflow: hidden; }
.challenge_list .challenge_item .item_thumb img { max-width: 100%; }
.challenge_list .challenge_item .item_status { display: flex; align-items: center; justify-content: center; width: 95px; height: 37px; position: absolute; top: 0; left: 0; background-color: #818590; color: #fff; font-weight: 700; border-bottom-right-radius: 10px; }
.challenge_list .challenge_item .item_status.underway { background-color: #004ec8; }
.challenge_list .challenge_item .item_info { padding: 20px 25px; background-color: #f8f8f8; }
.challenge_list .challenge_item .item_title { height: 58px; margin-bottom: 10px; font-size: 20px; font-weight: 700; text-overflow: ellipsis; display: -webkit-box; word-break: break-all; overflow: hidden; -webkit-line-clamp: 2; -webkit-box-orient: vertical; }
.challenge_list .challenge_item .item_desc { margin-bottom: 20px; font-size: 14px; color: #555; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
.challenge_list .challenge_item .more_btn { display: inline-block; position: relative; padding-right: 25px; font-size: 14px; color: #004ec8; }
.challenge_list .challenge_item .more_btn::after { content: ""; width: 20px; height: 20px; position: absolute; top: 50%; right: 0; transform: translate(0, -50%); border-radius: 10px; background: url(../img/arrow-blue-s.png) no-repeat center / 4px 8px; background-color: #ededed; }

@media all and (max-width: 1000px) {
    .visual_sec { height: 280px; }
    .visual_sec > div { margin-top: -30px; }
    .visual_sec h2 { font-size: 54px; }
    .visual_sec h3 { margin: 0 0 10px; font-size: 28px; }

    .challenge_wrap { margin: 40px auto; }
    .challenge_wrap .challenge_info { margin-bottom: 30px; }
    .challenge_wrap .challenge_info .info_item { gap: 10px; font-size: 18px; }
    .challenge_wrap .challenge_info .info_item em { font-size: 22px; }

    .challenge_list { gap: 20px; }
    .challenge_list .challenge_item { width: calc(33.33% - calc(40px / 3)); }
    .challenge_list .challenge_item .item_title { height: 54px; font-size: 18px; }
}

@media all and (max-width: 800px) {
    .challenge_list .challenge_item { width: calc(50% - 10px); }
}

@media all and (max-width: 640px) {
    .visual_sec { height: 250px; }
    .visual_sec h2 { font-size: 42px; }
    .visual_sec h3 { margin: -5px 0 5px; font-size: 24px; }

    .challenge_wrap .challenge_info { flex-direction: column; gap: 15px; height: auto; padding: 20px; }
    .challenge_wrap .challenge_info::after { content: none; }
    .challenge_wrap .challenge_info .info_item { justify-content: flex-start; font-size: 16px; }
    .challenge_wrap .challenge_info .info_item .info_image { width: 30px; height: 30px; }
    .challenge_wrap .challenge_info .info_item .info_data { margin-left: auto; }
    .challenge_wrap .challenge_info .info_item em { font-size: 20px; }
    .challenge_list .challenge_item { width: 100%; }
    .challenge_list .challenge_item .item_thumb { min-height: 205px; height: auto; }
}