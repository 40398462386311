.challenge_wrap > .top { padding-top: 50px; border-bottom: 1px solid #e2e2e2; }
.challenge_wrap .inner { max-width: 1040px; margin: 0 auto; padding: 0 20px; }
.challenge_wrap .top .inner { display: flex; flex-direction: column; gap: 35px; }
.challenge_wrap .top .inner > div { display: flex; align-items: center; justify-content: space-between; gap: 50px; }
.challenge_wrap .top .inner > div .left { width: 100%; }
.challenge_wrap .top .inner > div .right { flex-shrink: 0; width: 290px; }
.challenge_wrap .top .status { display: flex; align-items: center; justify-content: center; width: 95px; height: 37px; margin-bottom: 10px; color: #818590; font-weight: 700; border-radius: 5px; border: 1px solid #818590; }
.challenge_wrap .top .status.underway { color: #004ec8; border-color: #004ec8; }
.challenge_wrap .top .challenge_title { font-size: 34px; font-weight: 700; word-break: break-all; }
.challenge_wrap .top .challenge_desc { margin: 10px 0; color: #6a3f3f; }
.challenge_wrap .top .challenge_info { cursor: pointer; position: relative; display: flex; align-items: flex-start; justify-content: space-between; flex-wrap: wrap; gap: 5px 0; min-height: 50px; margin-top: auto; padding: 15px 30px; background-color: #f8f8f8; border-radius: 10px; transition: 0.3s; }
.challenge_wrap .top .challenge_info .info_item { flex-shrink: 0; display: flex; justify-content: center; gap: 10px; max-width: 100%; font-size: 15px; color: #222; font-weight: 500; }
.challenge_wrap .top .challenge_info .info_item .name { color: #004ec8; font-weight: 400; }

.challenge_wrap .top .challenge_info .hidden_detail { cursor: default; position: absolute; bottom: 100%; left: 50%; transform: translateX(-50%); opacity: 0; visibility: hidden; }
.challenge_wrap .top .challenge_info .hidden_detail > div { position: relative; padding: 5px; width: 250px; border-radius: 10px; background-color: #fff; box-shadow: 0 0 16px 4px rgba(1, 0, 0, 0.1); opacity: 0; visibility: hidden; transform: translateY(0px); }
.challenge_wrap .top .challenge_info .hidden_detail > div::after { content: ""; position: absolute; bottom: 0; left: 50%; width: 0; height: 0; border: 12px solid transparent; border-top-color: #fff !important; transform: translate(-50%, 24px); }
.challenge_wrap .top .challenge_info .hidden_detail ul { padding: 10px 15px; height: 100%; max-height: 200px; overflow-y: auto; }
.challenge_wrap .top .challenge_info .hidden_detail li { display: flex; gap: 0 15px; font-size: 14px; }
.challenge_wrap .top .challenge_info .hidden_detail li ~ li { margin-top: 6px; }
.challenge_wrap .top .challenge_info .hidden_detail .date { color: #004ec8; }
.challenge_wrap .top .challenge_info .hidden_detail .txt { font-weight: 500; }
.challenge_wrap .top .challenge_info:hover { background-color: #fff; box-shadow: inset 0 0 0 1px #1742d1; }
.challenge_wrap .top .challenge_info:hover .hidden_detail { opacity: 1; visibility: visible; }
.challenge_wrap .top .challenge_info:hover .hidden_detail > div { opacity: 1; visibility: visible; transform: translateY(-15px); transition: transform 0.3s 0.3s, 0.3s; }

.challenge_wrap .top .right { flex-shrink: 0; width: 290px; }
.challenge_wrap .top .challenge_thumb { border-radius: 10px; overflow: hidden; }
.challenge_wrap .top .challenge_thumb img { width: 100%; }
.challenge_wrap .top .right .cc_btn { font-size: 16px; }

.challenge_wrap .top .inner > .top_tabs { justify-content: flex-start; gap: 30px; margin: 0 -10px; margin-top: 5px; }
.challenge_wrap .top_tabs .tab_item { flex-shrink: 0; height: 80px; position: relative; padding: 0 10px; font-weight: 700; }
.challenge_wrap .top_tabs .tab_item.on { color: #004ec8; }
.challenge_wrap .top_tabs .tab_item.on::after { content: ""; width: calc(100% - 20px); height: 4px; position: absolute; bottom: 0; left: 50%; transform: translate(-50%, 0); background-color: #004ec8; }

.challenge_wrap .bottom .inner.small { max-width: 840px; }
.challenge_wrap .bottom_tabs { display: flex; gap: 10px; margin: 55px 0 45px; overflow: auto; }
.challenge_wrap .bottom_tabs .tab_item { flex-shrink: 0; display: flex; align-items: center; justify-content: center; height: 50px; padding: 0 25px; letter-spacing: -0.32px; font-weight: 500; border-radius: 25px; background-color: #f8f8f8; }
.challenge_wrap .bottom_tabs .tab_item.on { background-color: #004ec8; color: #fff; }

.challenge_wrap .bottom .default_md { margin-top: 50px; }
.challenge_wrap .bottom .default_md > div { max-width: 100%; }

.challenge_wrap .txt_wrap dl + dl { margin-top: 40px; }
.challenge_wrap .txt_wrap dt { margin-bottom: 15px; font-size: 24px; letter-spacing: -0.48px; font-weight: 700; }
.challenge_wrap .txt_wrap dd { line-height: 2; letter-spacing: 0.32px; }
.challenge_wrap .txt_wrap dd img { display: block; max-width: 800px; width: 100%; margin: 10px auto; }
.challenge_wrap .txt_wrap dd strong { font-weight: 700; }
.challenge_wrap .txt_wrap dd a { color: #004ec8; padding-bottom: 3px; border-bottom: 1px solid #004ec8; }

.bbs_wrap { margin-top: 30px; }
.bbs_wrap .top { display: flex; align-items: center; justify-content: center; padding: 30px 0; }
.bbs_wrap .top.flex { justify-content: space-between; }
.bbs_wrap .top .table_title { font-size: 28px; font-weight: 700; }
.bbs_wrap .top .cc_btn { flex-shrink: 0; width: 121px; font-size: 16px; }
.bbs_wrap .bbs_table table { width: 100%; border-top: 1px solid #000; }
.bbs_wrap .bbs_table th, .bbs_wrap .bbs_table td { padding: 10px; border-bottom: 1px solid #e2e2e2; text-align: center; letter-spacing: -0.32px; }
.bbs_wrap .bbs_table th { height: 70px; }
.bbs_wrap .bbs_table td { height: 80px; color: #555; }
.bbs_wrap .bbs_table th.ta_left, .bbs_wrap .bbs_table td.ta_left { padding-left: 35px; text-align: left; color: #000; }
.bbs_wrap .bbs_table .table_badge { display: flex; align-items: center; justify-content: center; width: 50px; height: 30px; margin: 0 auto; border-radius: 5px; background-color: #1742d1; color: #fff; font-size: 14px; }
.bbs_wrap .bbs_table td.lock div { position: relative; }
.bbs_wrap .bbs_table td.lock div::before { content: ""; width: 13px; height: 16px; position: absolute; top: 3px; left: -20px; background: url(../img/icon-lock.png) no-repeat center / 100%; }

.enter_item + .enter_item { margin-top: 20px; }
.enter_wrap textarea { max-height: 450px; }
.enter_item .enter .check_item label { padding: 0; }

.view_wrap .view_top { display: flex; flex-direction: column; gap: 10px; padding: 30px 0; border-bottom: 1px solid #e2e2e2; text-align: left; }
.view_wrap .view_top .title { font-size: 24px; font-weight: 700; }
.view_wrap .view_top .title.lock { position: relative; padding-left: 30px; }
.view_wrap .view_top .title.lock::before { content: ""; width: 20px; height: 25px; position: absolute; top: 3px; left: 0; background: url(../img/icon-lock.png) no-repeat center / 100%; }
.view_wrap .view_top .info { display: flex; gap: 21px; color: #555; }
.view_wrap .view_top .info span:first-child { position: relative; }
.view_wrap .view_top .info span:first-child::after { content: ""; width: 1px; height: 12px; position: absolute; top: 50%; right: -11px; transform:  translate(0, -50%); background-color: #ccc; }
.view_wrap .view_bottom { padding: 30px 0; border-bottom: 1px solid #e2e2e2; }

.comment_wrap { padding-top: 30px; }
.comment_wrap .comment_enter { padding: 10px; background-color: #f3f3f3; border-radius: 5px; }
.comment_wrap .comment_enter.reply { padding: 25px 10px 30px; background-color: #fff; border-bottom: 1px solid #e2e2e2; }
.comment_wrap .enter_item .enter.flex input { width: calc(100% - 100px); }
.comment_wrap .enter_item .enter.flex .cc_btn { width: 90px; }
.comment_wrap .comment_list { margin-top: 10px; }
.comment_wrap .comment_item { display: flex; flex-direction: column; gap: 10px; position: relative; padding: 20px 0; border-bottom: 1px solid #e2e2e2; letter-spacing: -0.32px; }
.comment_wrap .comment_item.my_comment { padding-right: 40px; }
.comment_wrap .comment_item.reply { padding: 20px 40px; }
.comment_wrap .comment_item.delete { text-align: center; color: #999; text-decoration: line-through; }
.comment_wrap .comment_item .info { display: flex; align-items: center; gap: 21px; font-size: 14px; color: #555; }
.comment_wrap .comment_item .info > span:first-child { position: relative; }
.comment_wrap .comment_item .info > span:first-child::after { content: ""; width: 1px; height: 12px; position: absolute; top: 50%; right: -11px; transform: translate(0, -50%); background-color: #ccc; }
.comment_wrap .comment_item .info .cc_btn { width: auto; height: auto; padding: 5px; background-color: #fff; font-size: 14px; font-weight: 400; }
.comment_wrap .comment_item .info .cc_btn span { padding-bottom: 3px; border-bottom: 1px solid #004ec8; color: #004ec8; }
.comment_wrap .comment_item .delete_btn { position: absolute; top: 25px; right: 0; font-size: 14px; color: #d83a3a; padding-bottom: 3px; border-bottom: 1px solid #d83a3a; }

.team_box_list { display: flex; flex-wrap: wrap; gap: 30px; }
.team_box_list .team_box { width: calc(50% - 15px); padding: 20px 35px 25px; }
.team_box_list .team_box .team_name { margin-bottom: 10px; font-size: 20px; border-bottom: 1px solid #e2e2e2; word-break: break-all; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }
.team_box_list .team_box .team_info .info_item { min-height: auto; padding-left: 35px; border-top: 0; }
.team_box_list .team_box .team_info .info_item::before { background-position: center 15px; }
.team_box_list .team_box .team_info .info_item.member::before { width: 21px; height: 24px; top: 10px; background: url(../img/icon-member.png) no-repeat center / 100%; }
.team_box_list .team_box .team_info .info_item.intro::before { width: 20px; height: 21px; top: 12px; background: url(../img/icon-comment.png) no-repeat center / 100%; }
.team_box_list .team_box .team_info .info_item.intro > div { word-break: break-all; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }
.team_box { width: 100%; padding: 30px 40px; border: 1px solid #e2e2e2; border-radius: 10px; }
.team_box .team_name { padding: 5px 0 20px; font-size: 24px; font-weight: 700; word-break: break-all; }
.team_box .change_btn { flex-shrink: 0; height: 100%; padding: 10px; font-size: 14px; }
.team_box .change_btn span { color: #004ec8; padding-bottom: 2px; border-bottom: 1px solid #004ec8; }
.team_box .team_info .info_item { display: flex; align-items: center; justify-content: space-between; gap: 10px; min-height: 60px; position: relative; padding: 10px; padding-left: 65px; padding-right: 0; border-top: 1px solid #e2e2e2; font-size: 14px; }
.team_box .team_info .info_item::before { content: ""; width: 40px; height: 100%; position: absolute; top: 0; left: 0; background-repeat: no-repeat; background-position: center 20px; }
.team_box .team_info .info_item.condition::before { background-image: url(../img/icon-option.png); background-size: 20px; }
.team_box .team_info .info_item.intro::before { background-image: url(../img/icon-comment.png); background-size: 20px 21px; }
.team_box .team_info .info_item.member::before { background-image: url(../img/icon-member.png); background-size: 21px 24px; }
.team_box .team_info .info_item.member { padding-right: 10px; }
.team_box .team_option { display: flex; align-items: center; gap: 1px; height: 40px; position: relative; margin-top: 15px; background-color: #f2f7fe; border-radius: 10px; }
.team_box .team_option::after { content: ""; width: 1px; height: 12px; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: #e2e2e2; }
.team_box .team_option span { width: 100%; text-align: center; font-size: 14px; letter-spacing: -0.28px; color: #555; }

.team_enter_box { padding: 50px; background-color: #f8f8f8; border-radius: 10px; }
.team_enter_box .consent_wrap { margin: 0; }
.team_enter_box .consent_wrap .consent_box { height: 100px; background-color: #fff; color: #555; }
.team_enter_box .consent_wrap .enter_item { margin-top: 10px; }
.team_enter_box .consent_wrap .consent_item.all_check { margin-bottom: -5px; }
.team_enter_box .consent_wrap .consent_item.all_check .enter_item { margin-top: 0; }
.team_enter_box .consent_wrap .consent_item.all_check span { font-size: 18px; }
.team_enter_box .enter_wrap .enter_item { display: flex; flex-direction: column; gap: 10px; }
.team_enter_box .enter_wrap .enter_item .item_title { margin: 0; font-size: 16px; color: #000; font-weight: 500; }
.team_enter_box .enter_wrap .enter_item .option_list { display: flex; align-items: center; }
.team_enter_box .enter_wrap .enter_item .option_list .radio_item { width: 25%; }
.team_enter_box .enter_item + .enter_item { margin-top: 15px; }

.member_list { display: flex; flex-direction: column; gap: 15px; padding: 5px 0; }
.member_list .member_item { display: flex; flex-wrap: wrap; align-items: center; gap: 8px; position: relative; padding-left: 50px; }
/* .member_list .member_item::before { content: "[팀원]"; line-height: 30px; position: absolute; top: 0; left: 0; font-weight: 500; font-size: 14px; }
.member_list .member_item.leader::before { content: "[팀장]"; } */
.member_list .member_item::before { content: "[Member]"; line-height: 31px; position: absolute; top: 0; left: 0; font-weight: 500; font-size: 11px; }
.member_list .member_item.leader::before { content: "[Lead]"; }
.member_list .member_item > span { display: flex; align-items: center; justify-content: center; height: 30px; padding: 0 8px; font-size: 14px; background-color: #f8f8f8; border-radius: 10px; }
.member_list .member_item.leader > span { background-color: #f2f7fe; }
.member_list .member_item .change_btn { padding: 0; }

.request_wrap { margin-top: 75px; }
.request_enter { margin-bottom: 30px; }
.request_enter input::placeholder { color: #004ec8 !important; }
.request_enter .enter_item .enter.flex input { width: calc(100% - 130px); }
.request_enter .enter_item .enter.flex .cc_btn { width: 120px; }
.request_list { display: flex; flex-direction: column; gap: 10px; }
.request_list .request_item { display: flex; align-items: center; justify-content: space-between; position: relative; padding: 25px 30px; padding-left: 93px; background-color: #f8f8f8; border-radius: 10px; }
.request_list .request_item::before { content: ""; width: 43px; height: 43px; position: absolute; top: 50%; left: 25px; transform: translate(0, -50%); background: url(../img/icon-my-comment.png) no-repeat center / 100% }
.request_item .request_top { display: flex; align-items: center; gap: 10px; }
.request_item .request_top .date { font-size: 14px; color: #555; }
.request_item .request_comment { margin-top: 5px; letter-spacing: -0.32px; }
.request_item .request_btn_wrap { display: flex; align-items: center; margin-right: -10px; }
.request_item .request_btn_wrap .request_btn { padding: 10px; }
.request_item .request_btn_wrap .request_btn span { color: #004ec8; padding-bottom: 2px; border-bottom: 1px solid #004ec8; }
.request_item .request_btn_wrap .request_btn.reject span, .request_item .request_btn_wrap .request_btn.delete span { color: #d83a3a; border-color: #d83a3a; }

.bottom_btn_wrap { display: flex; align-items: center; justify-content: center; gap: 10px; margin: 30px 0; }
.bottom_btn_wrap .cc_btn { width: 130px; font-size: 16px; font-weight: 400; }

.mypage_wrap { max-width: 1040px; width: 100%; margin: 70px auto; padding: 0 20px; }
.mypage_wrap .title { font-size: 34px; font-weight: 700; text-align: center; }
.mypage_wrap .cc_tabs { display: flex; align-items: center; justify-content: center; gap: 10px; margin: 40px 0 0; }
.mypage_wrap .cc_tabs .tab_item { height: 50px; padding: 0 25px; border-radius: 25px; background-color: #f8f8f8; letter-spacing: -0.32px; }
.mypage_wrap .cc_tabs .tab_item.on { background-color: #004ec8; color: #fff; }

.mypage_wrap .mypage_enter_box { max-width: 580px; position: relative; margin: 120px auto 0; padding: 70px; padding-bottom: 30px; border: 1px solid #e2e2e2; border-radius: 10px; }
.mypage_wrap .mypage_enter_box::before { content: ""; width: 100px; height: 100px; position: absolute; top: -50px; left: 50%; transform: translate(-50%, 0); border-radius: 50%; background: url(../img/icon-l-my.png) no-repeat center / 48px 50px; background-color: #ebebeb; }
.mypage_wrap .mypage_enter_box .enter_item .enter.flex { flex-wrap: nowrap; justify-content: flex-start; }
.mypage_wrap .mypage_enter_box .enter_item .enter.flex .radio_item { width: 100%; }
.mypage_wrap .bottom_btn_wrap { flex-direction: column; gap: 20px; }
.mypage_wrap .bottom_btn_wrap .cc_btn { width: 100%; font-size: 18px; font-weight: 700; }
.mypage_wrap .bottom_btn_wrap .reset_password { width: auto; height: auto; padding: 10px; }
.mypage_wrap .bottom_btn_wrap .reset_password span { padding-bottom: 3px; border-bottom: 1px solid #000; }

@media all and (max-width: 1000px) {
    .challenge_wrap .top { padding-top: 20px; }
    .challenge_wrap .top .inner { gap: 10px; }
    .challenge_wrap .top .inner > div { flex-direction: column; gap: 20px; }
    .challenge_wrap .top .inner > .inner_top { flex-direction: column-reverse; }
    .challenge_wrap .top .inner > .inner_top .left { display: flex; flex-direction: column; gap: 10px; }
    .challenge_wrap .top .inner > div .right { width: 100%; }
    .challenge_wrap .top .status { margin-bottom: 0; font-size: 14px; }
    .challenge_wrap .top .challenge_title { font-size: 28px; }
    .challenge_wrap .top .challenge_desc { margin: 5px 0 10px; font-size: 14px; } 
    .challenge_wrap .top .challenge_info .info_item { font-size: 14px; }

    .challenge_wrap .top .inner > .top_tabs { flex-direction: row; gap: 10px; }
    .challenge_wrap .top_tabs .tab_item { height: 50px; font-size: 14px; }
    .challenge_wrap .top_tabs .tab_item.on::after { height: 2px; }

    .challenge_wrap .bottom_tabs { margin: 20px 0; }
    .challenge_wrap .bottom_tabs .tab_item { height: 40px; padding: 0 15px; font-size: 14px; }

    .challenge_wrap .txt_wrap dt { font-size: 18px; margin-bottom: 10px; }
    .challenge_wrap .txt_wrap dd { line-height: 1.5; font-size: 14px; }

    .bbs_wrap { margin-top: 20px; }
    .bbs_wrap .top { padding: 20px 0; }
    .bbs_wrap .top .table_title { font-size: 20px; }
    .bbs_wrap .top .cc_btn { width: 80px; height: 40px; font-size: 14px; }
    .bbs_wrap .bbs_table colgroup, .bbs_wrap .bbs_table thead { display: none; }
    .bbs_wrap .bbs_table tbody tr { display: flex; flex-wrap: wrap; gap: 5px 11px; padding: 15px; border-bottom: 1px solid #e2e2e2; }
    .bbs_wrap .bbs_table tbody td { width: 100%; height: auto; padding: 0; border-bottom: 0; text-align: left; font-size: 14px; }
    .bbs_wrap .bbs_table td.ta_left { padding-left: 0; }
    .bbs_wrap .bbs_table .table_badge { justify-content: flex-start; width: auto; height: auto; margin: 0; background-color: transparent; color: #004ec8; font-weight: 700; }
    .bbs_wrap .bbs_table td.lock div { padding-left: 15px; }
    .bbs_wrap .bbs_table td.lock div::before { width: 10px; height: 12px; left: 0; }

    .bbs_wrap .bbs_table.type_list td::before { content: attr(data-th); flex-shrink: 0; width: 90px; font-weight: 700; }
    .bbs_wrap .bbs_table.type_list tbody td { display: flex; }
    
    .enter_item + .enter_item { margin-top: 10px; }
    .enter_wrap textarea { max-height: 300px; }

    .view_wrap .view_top { gap: 5px; padding: 15px 0; }
    .view_wrap .view_top .title { font-size: 20px; }
    .view_wrap .view_top .title.lock { padding-left: 20px; }
    .view_wrap .view_top .title.lock::before { width: 15px; height: 18px; top: 5px; }
    .view_wrap .view_top .info { font-size: 14px; }
    .comment_wrap { padding-top: 10px; }
    .comment_wrap .comment_item { gap: 5px; padding: 10px 0; }
    .comment_wrap .comment_item.reply { padding: 10px 20px; }
    .comment_wrap .comment_item .comment { font-size: 14px; }
    .comment_wrap .comment_item .info { gap: 11px; font-size: 12px; }
    .comment_wrap .comment_item .info span:first-child::after { right: -6px; }
    .comment_wrap .comment_enter.reply { padding: 10px; }

    .team_box_list { gap: 20px; }
    .team_box_list .team_box { width: calc(50% - 10px); padding: 15px 20px 20px; }
    .team_box_list .team_box .team_name { padding: 5px 0 15px; }
    .team_box_list .team_box .team_info .info_item { padding: 5px 10px; padding-left: 35px; }
    .team_box_list .team_box .team_info .info_item.member::before { top: 4px; }
    .team_box_list .team_box .team_info .info_item.intro::before { top: 8px; }
    .team_box { padding: 20px 30px; }
    .team_box .team_info .info_item { padding-left: 45px; }
    .team_box .team_option { margin-top: 5px; }

    .team_enter_box { padding: 30px; }
    .team_enter_box .consent_wrap .consent_item.all_check span { font-size: 16px; }
    .team_enter_box .enter_wrap { margin-top: 20px; }
    .team_enter_box .enter_wrap .enter_item .item_title { font-size: 14px; }

    .request_wrap { margin-top: 35px; }
    .request_enter { margin-bottom: 20px; }
    .request_enter .enter_item .enter.flex input { width: calc(100% - 110px); }
    .request_enter .enter_item .enter.flex .cc_btn { width: 100px; }
    .request_list .request_item { padding: 10px 20px; padding-left: 70px; font-size: 14px; }
    .request_list .request_item::before { width: 30px; height: 30px; left: 20px; }
    .request_item .request_top .date { font-size: 12px; }
    .request_item .request_comment { margin-top: 0; }
    .request_item .request_btn_wrap { gap: 0; }

    .bottom_btn_wrap { flex-wrap: wrap; margin: 20px 0; }
    .bottom_btn_wrap .cc_btn { font-size: 14px; }

    .mypage_wrap { margin: 30px auto; }
    .mypage_wrap .title { font-size: 24px; }
    .mypage_wrap .cc_tabs { margin-top: 20px; margin-bottom: 5px; }
    .mypage_wrap .cc_tabs .tab_item { height: 40px; padding: 0 15px; font-size: 14px; }

    .mypage_wrap .mypage_enter_box { margin-top: 70px; padding: 40px; padding-bottom: 10px; }
    .mypage_wrap .mypage_enter_box::before { width: 74px; height: 74px; top: -37px; background-size: 35px 36px; }
    .mypage_wrap .bottom_btn_wrap { gap: 10px; }
}

@media all and (max-width: 800px) {
    .team_box_list { gap: 10px; }
    .team_box_list .team_box { width: 100%; }
    .team_box_list .team_box .team_info .info_item.intro > div { display: block; height: auto; }
    .team_box .team_option { margin-top: 25px; }
}

@media all and (max-width: 640px) {
    .challenge_wrap .top .challenge_title { font-size: 20px; }
    .challenge_wrap .top .challenge_info { flex-wrap: wrap; gap: 10px; height: auto; padding: 15px 20px; }
    .challenge_wrap .top .challenge_info .info_item { width: calc(50% - 5px); justify-content: flex-start; }
    .challenge_wrap .top .challenge_info .info_item .name { flex-shrink: 0; width: 45px; }

    .bbs_wrap .bbs_table tbody tr { padding: 10px; }

    .team_box_list .team_box { margin: 0; border: 1px solid #e2e2e2; }
    .team_box_list .team_box .team_info .info_item::before { content: ""; }
    .team_box { margin-top: 10px; padding: 0; border: 0; }
    .team_box .team_name { padding: 0; padding-bottom: 10px; font-size: 20px; }
    .team_box .team_info .info_item::before { content: none; }
    .team_box .team_info .info_item { padding-left: 10px; }
    .team_box .team_option span { font-size: 12px; }

    .team_enter_box { padding: 0; background-color: #fff; }
    .team_enter_box .enter_wrap .enter_item .option_list { flex-direction: column; align-items: flex-start; }
    .team_enter_box .enter_wrap .enter_item .option_list .radio_item { width: 100%; }
    .team_enter_box .enter_wrap .enter_item label { padding: 5px 0; }
    
    .member_list .member_item { padding-left: 0; }
    .member_list .member_item::before { width: 100%; position: static; margin-bottom: -5px; }

    .request_list .request_item { flex-direction: column; align-items: flex-start; padding-left: 20px; }
    .request_list .request_item::before { content: none; }
    .request_item .request_top { flex-direction: column; align-items: flex-start; gap: 0; }
    .request_item .request_comment { margin-top: 5px; }
    .request_item .request_btn_wrap { width: 100%; margin: 0; margin-top: 10px; }
    .request_item .request_btn_wrap .request_btn { width: 100%; }

    .bottom_btn_wrap .cc_btn { width: calc(50% - 5px); }

    .mypage_wrap .mypage_enter_box { margin-top: 20px; padding: 0; border: 0; }
    .mypage_wrap .mypage_enter_box::before { content: none; }
    .mypage_wrap .bottom_btn_wrap .cc_btn { font-size: 14px; }
    .mypage_wrap .bottom_btn_wrap .reset_password { font-size: 14px; }
}

@media all and (max-width: 560px) {
    .challenge_wrap .top .challenge_info { gap: 5px; }
    .challenge_wrap .top .challenge_info .info_item { width: 100%; }
}

@media all and (max-width: 420px) {
    .challenge_wrap .top .inner > .top_tabs { gap: 3px; }
}
