.bbs_wrap .bbs_table td { height: 90px; }
.bbs_wrap .bbs_table td .challenge { display: flex; flex-wrap: wrap; gap: 5px; }
.bbs_wrap .bbs_table td .challenge .status { color: #555; }
.bbs_wrap .bbs_table td .challenge .status.underway { color: #004ec8; }
.bbs_wrap .bbs_table td .member { margin-top: 5px; color: #555; }

.cc_btn.underline { width: auto; height: auto; padding: 10px; background-color: #fff; font-size: 16px; letter-spacing: -0.32px; font-weight: 400; }
.cc_btn.underline span { padding-bottom: 5px; border-bottom: 1px solid #004ec8; color: #004ec8; }

@media all and (max-width: 1000px) {
    .bbs_wrap { margin-top: 0; }
    .bbs_wrap .bbs_table tbody tr { position: relative; padding-right: 130px; }
    .bbs_wrap .bbs_table tbody td:nth-child(3) { position: absolute; top: 50%; right: 10px; width: 100px; text-align: center; transform: translateY(-50%); }
    .bbs_wrap .bbs_table tbody td:nth-child(4), .bbs_wrap .bbs_table tbody td:nth-child(5) { width: auto; position: relative; }
    .bbs_wrap .bbs_table tbody td:nth-child(5)::before { content: "/"; position: absolute; top: 45%; left: -8px; transform: translate(0, -50%); }
    .bbs_wrap .bbs_table tbody td:nth-child(6) > div { display: flex; flex-wrap: wrap; gap: 5px; }
    .cc_btn.underline { width: 100px; height: 35px; border:  1px solid #004ec8; font-size: 14px; }
    .cc_btn.underline span { padding: 0; border: 0; }
}